<template>
  <v-text-field
    v-model="model"
    v-bind="$attrs"
  >
  </v-text-field>
</template>

<script>
export default {
  props: ['value'],
  computed:{
    model:{
      get(){
        return this.value
      },
      set(v){
        this.$emit('input', v)
      }
    }
  }
}
</script>